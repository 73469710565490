.mobea-refund-billing {
  padding: 32px;
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  &__text {
    color: var(--color-gray-400);
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0 0 32px;
  }

  &__empty-space {
    flex: 1 1 auto;
    min-height: 32px;
  }
}
