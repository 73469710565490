.mobea_logo_lang_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > :first-child {
    width: 33%;

    @media screen and (max-width: 400px) {
      width: 111px;
    }

    @media screen and (min-width: 620px) {
      width: 183px;
    }

    > img,
    svg {
      width: 100%;
    }
  }

  .mobea__dropdown__options {
    left: 0 !important;
  }

  .mobea__settings__language-picker.mobea__dropdown {
    margin-right: 0;
  }
}
