.mobea-pre-login {
  height: 100%;
  background: url("/static/images/moveasy_welcome_bg.jpg");
  background-size: cover;
  background-position: center;
  padding: 32px;

  & > section {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;


  .logo-wrapper {
      position: absolute;
      top: 15%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      img {
        max-width: 220px;
      }

     p {
        line-height: 40px;
        font-size: 20px;
        font-family: var(--font-family);
      }
    }

    &::before {
      content: '';
      flex-grow: 1;
    }

  }
}
