.mobea-profile-registered {
  padding: 32px;


  &__content {

    flex: 1 0 auto;
  }

  &__headline {
    padding-top: 8px;
    margin-bottom: 32px;

    &__name {
      margin: 0;
      margin-bottom: 32px;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      font-weight: 400;
    }

    &__icon {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin: auto;
      position: relative;
      overflow: hidden;

      &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-primary);
        opacity: 0.2;
      }

      > svg {
        width: 32px;
        height: 32px;
        display: block;
        color: var(--color-primary);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  .mobea-profile__email {
    width: 100%;
  }

  .mobea__label-value {
    margin-bottom: 16px;
  }

  &__empty-space {
    flex: 1 1 auto;
    min-height: 32px;
  }
}
