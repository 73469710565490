.mobea-reset-password {
  padding: 32px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 360px) {
    padding: 32px 24px;
  }

  &__content {
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  &__headline {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    font-weight: 400;
    margin: 0 0 24px;

    @media screen and (max-width: 360px) {
      font-size: 28px;
    }
  }

  &__text {
    margin: 0 0 32px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--color-gray-400);
  }

  &__spacer {
    flex: 1 0 auto;
  }

  .mobea-password {
    margin-bottom: 24px;
  }

  .result-icon {
    display: block;
    margin: 24px auto 0;
  }

  &__label {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    display: block;
    margin-bottom: 8px;
    color: var(--color-gray-300);
  }
}
