.mobea-forgotten-password {
  &__content {
    padding-top: 48px;
  }

  &__headline {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    font-weight: 400;
    margin: 0 0 24px;
  }

  &__text {
    margin: 0 0 32px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--color-gray-400);
  }

  &__sent {
    padding: 0 8px;

    .phone-number {
      white-space: nowrap;
      font-weight: bold;
    }

    p.last {
      margin-bottom: 0;
    }

    .send-again-button {
      font-size: 16px;
      line-height: 24px;
      text-transform: initial;
      outline: 0 none;
    }
  }

  .mobea__phone-number-picker {
    margin-bottom: 24px;
  }
}
