.mobea-password {
  padding: 0.05px; // preventing margin collapse causing jumping on focus because of the thicker border

  & > .pw-input {
    display: flex;
    font-size: 16px;
    font-family: var(--font-family-numbers);
    position: relative;

    &.error > label {
      color: var(--color-error) !important;
    }

    & > label {
      font-size: 16px;
      line-height: 24px;
      position: absolute;
      left: 0;
      top: -10px;
      transform: translate(0, 25px) scale(1);
      transform-origin: left;
      pointer-events: none;
      color: var(--color-gray-200);
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1),
        transform 200ms cubic-bezier(0, 0, 0.2, 1);
    }

    &.focused > label,
    &.non-empty > label {
      transform: scale(0.75);
    }

    &.focused > label {
      color: var(--color-primary);
    }

    & > input {
      letter-spacing: 2px;
      height: 32px;
      border: none;
      width: 100%;
      outline: none;
    }

    &.empty > input {
      letter-spacing: 0;
    }

    & > button {
      border: 0;
      background: none;
      margin: 0;
      padding: 2px 4px;
      vertical-align: middle;
      line-height: 0;
      margin-left: 8px;
      color: var(--color-gray-200);
    }

    &.blured.empty > button {
      opacity: 0;
      pointer-events: none;
    }

    &.pw-shown > button {
      color: var(--color-primary);
    }
  }

  &.standard > .pw-input {
    border-bottom: 1px solid var(--color-gray-100);
    padding-top: 8px;
    margin-bottom: 8px;

    &.error {
      border-color: var(
        --color-error
      ) !important; // important is to override focused underline color
    }

    &.focused {
      margin-bottom: 7px;
      border-bottom: 2px solid var(--color-primary);
    }
  }

  &.outlined > .pw-input {
    border: 1px solid var(--color-gray-100);
    border-radius: 2px;
    padding: 8px 12px;
    margin: 1px 0 8px 0;

    &.focused {
      padding-right: 11px;
      margin: 0 0 7px 0;
      border: 2px solid var(--color-primary);
    }
  }

  &.outlined > .pw-input.error {
    border-color: var(--color-error);
  }

  // great pw text color
  & > .pw-strength.pw-ok > :first-child {
    color: green;
  }

  & > .pw-strength {
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
    align-items: baseline;
    line-height: 16px;

    // pw strength text
    & > :first-child {
      color: var(--color-gray-200);
    }

    // progress bar
    & > :last-child {
      color: var(--color-gray-100);
      height: 8px;
      width: 32px;
      border-radius: 4px;
      background-color: var(--color-gray-100);
      margin-left: 8px;
      position: relative;
      flex-shrink: 0;

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 8px;
        border-radius: 4px;
      }
    }

    &.pw-ok > :last-child::after {
      background-color: #009e2b;
      width: 32px;
    }

    &.pw-short > :last-child::after {
      background-color: var(--color-error);
      width: 12px;
    }

    &.pw-missing_uppercase > :last-child::after,
    &.pw-missing_symbol > :last-child::after {
      background-color: #ffa500;
      width: 20px;
    }
  }

  & > .error-message {
    font-size: 12px;
    align-items: baseline;
    line-height: 16px;
    color: var(--color-error);
  }
}
