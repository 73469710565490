.mobea-invalid-login-token {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::after {
    content: "";
  }

  & .icon {
    width: 100%;
    margin: 0 auto 30px;
    color: var(--color-gray-200);
  }

  a.mobea__button {
    height: auto !important;
  }
}
